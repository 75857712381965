import React from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import LoginLogout from './LoginLogout';
const CONTACT_US = 'https://ca-chinohills3.civicplus.com/70/Code-Enforcement';
function Footer({ className }) {
    return (<footer className={cx('footer', className)} data-testid="footer">
      <div className="footer-wrapper">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Comcate, Inc.
        </p>
        <nav className="footer-nav">
          <a className="footer-link" target="_blank" href={CONTACT_US}>
            <Trans>Contact Us</Trans>
          </a>
          <LoginLogout className="footer-link"/>
        </nav>
      </div>
    </footer>);
}
export default Footer;
