export const DEFAULT_CRM_TOKEN = '2ysz1dvfru';
export const brandLogo = `${window.assetsLocation}/images/logo.png`;
export const brandShowcaseUrl = `${window.assetsLocation}/images/showcase.png`;
export const bannerUrl = `${window.assetsLocation}/images/banner.png`;
export const viewReportIconUrl = `${window.assetsLocation}/images/carousel.png`;
export const homeArticleIds = ['11'];
export const faqArticleIds = [
    '12',
    '13',
    '14',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '15',
    '16',
    '17',
    '18',
];
