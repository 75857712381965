import * as React from "react";
import { useTranslation } from "@elevio/kb-kit/lib/hooks";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import WithTitle from '@elevio/kb-kit/lib/componentsInternal/WithTitle';
function Page() {
    const { t } = useTranslation();
    const [lang, setLang] = React.useState('en');
    const [lat, setLat] = React.useState('');
    const [long, setLong] = React.useState('');
    let isMedianApp = false;
    if (navigator.userAgent.indexOf('median') > -1) {
        isMedianApp = true;
    }
    if (isMedianApp) {
        if (navigator.userAgent.indexOf('MedianAndroid') > -1) {
            // @ts-ignore
            gonative.android.geoLocation.promptLocationServices();
        }
    }
    React.useEffect(() => {
        if (isMedianApp) {
            elevio_geolocation_ready((e) => {
                setLat(e.coords.latitude);
                setLong(e.coords.longitude);
            }, (err) => {
                console.log(`Error code: ${err.code}\n${err.message}`);
            });
        }
        const interval = window.setInterval(function () {
            if (document.cookie) {
                let selectedLang = check_cookie_name('googtrans');
                if (lang !== selectedLang) {
                    setLang(selectedLang);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    function check_cookie_name(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
        else {
            return '/en/en';
        }
    }
    function elevio_geolocation_ready(successCB, errorCB) {
        const locationOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };
        navigator.geolocation.getCurrentPosition(successCB, errorCB, locationOptions);
    }
    let url = new URL(process.env.PRIVATE_SUBMISSION_URL);
    url.searchParams.append('translate', lang);
    if (isMedianApp && lat && long) {
        url.searchParams.append('is_median_app', isMedianApp.toString());
        url.searchParams.append('lat', lat);
        url.searchParams.append('long', long);
    }
    return (<PageLayout className="hide-submit-request" header={<Header />} footer={<Footer />}>
      <WithTitle title="Submit Request" children></WithTitle>
     <div className="request-page">
      <iframe src={url.href} allow={`geolocation ${url.origin}`}/>
    </div>
    </PageLayout>);
}
export default Page;
